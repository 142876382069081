<template>
    <div class="bc-wrapper bc-horizontal">
        <h1>Success</h1>
        <p>You have become a partner</p>
        <div class="success">
            <successful-payment-icon />
        </div>
    </div>
</template>

<script>

import SuccessfulPaymentIcon from '@/components/icons/SuccessfulPaymentIcon.vue';

export default {
    name: 'AffiliateSuccess',
    components: {
        SuccessfulPaymentIcon
    }
}
</script>

<style lang="scss">
    .success{
        width: 100%;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
            width: 300px;
            height: 190px;
        }
    }
</style>
